import React, { Fragment } from "react"

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    This is my personal site where I drop notes and articles about things that I am interested in.
  </Fragment>
)
